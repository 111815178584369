import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { Summary } from '@stsm/summaries/models/summary';

import { SummariesStoreFacade } from '../summaries-store-facade.service';

@Pipe({
  name: 'summaryById',
  standalone: true,
})
export class SummaryByIdPipe implements PipeTransform {
  constructor(private readonly _summariesStoreFacade: SummariesStoreFacade) {}

  transform(summaryId: number): Observable<Summary> {
    return this._summariesStoreFacade.summaryById(summaryId);
  }
}
